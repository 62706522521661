<netz-page-heading size="xl">Legislation</netz-page-heading>

<div class="govuk-!-width-two-thirds">
  <p>
    References are made throughout the service to the following pieces of legislation which set out specific terms of
    the scheme:
  </p>

  <p>
    <a
      class="govuk-link"
      href="https://www.legislation.gov.uk/uksi/2012/1976"
      rel="noreferrer noopener"
      target="_blank"
    >
      The Climate Change Agreements (Administration) Regulations 2012
    </a>
  </p>

  <p>
    <a
      class="govuk-link"
      href="https://www.legislation.gov.uk/uksi/2012/2999/contents/made"
      rel="noreferrer noopener"
      target="_blank"
    >
      The Climate Change Agreements (Eligible Facilities) Regulations 2012
    </a>
  </p>

  <p>
    These regulations have been amended several times since their introduction. You should read both the original
    regulation and their subsequent amendments.
  </p>

  <p>
    These regulations were made under the powers set out in paragraphs 52D-F of Schedule 6 of the
    <a
      class="govuk-link"
      href="https://www.legislation.gov.uk/ukpga/2000/17/schedule/6"
      rel="noreferrer noopener"
      target="_blank"
    >
      Finance Act 2000
    </a>
  </p>
</div>
