@if (showCookiesBanner() === true) {
  <cca-cookies-container></cca-cookies-container>
}

<govuk-header title="Manage your Climate Change Agreement">
  <govuk-skip-link />
  <govuk-header-actions-list>
    @if (isLoggedIn()) {
      <a class="govuk-header__link" routerLink="." (click)="authService.logout()"> Sign out </a>
    }
  </govuk-header-actions-list>

  @if (isAuthorized()) {
    <govuk-header-nav-list identifier="navigation" ariaLabel="Primary navigation">
      <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
        <a routerLinkActive class="govuk-header__link" [routerLink]="['/dashboard']"> Tasks </a>
      </li>

      <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
        <a routerLinkActive class="govuk-header__link" [routerLink]="['/target-unit-accounts']">
          Target unit accounts
        </a>
      </li>

      @if (showSectors()) {
        <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
          <a class="govuk-header__link" [routerLink]="['/sectors']"> Manage sectors </a>
        </li>
      }

      @if (showRegulators()) {
        @if (!subsistenceFeesHideMenu) {
          <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
            <a routerLinkActive class="govuk-header__link" [routerLink]="['/subsistence-fees']"> Subsistence fees </a>
          </li>
        }
        <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
          <a routerLinkActive class="govuk-header__link" [routerLink]="['/user/regulators']"> Regulator users </a>
        </li>
        <li routerLinkActive="govuk-header__navigation-item--active" class="govuk-header__navigation-item">
          <a routerLinkActive class="govuk-header__link" [routerLink]="['/mi-reports']"> MI Reports </a>
        </li>
      }
    </govuk-header-nav-list>
  }
</govuk-header>

<section class="govuk-width-container govuk-body">
  <cca-phase-bar [isUserLoggedIn]="isLoggedIn()" />
  <cca-workflow-task-header />
  <netz-breadcrumbs />
  <netz-back-link />
  <main class="govuk-main-wrapper" id="main-content" role="main" tabindex="0">
    <router-outlet></router-outlet>
  </main>
</section>

<govuk-footer>
  <govuk-footer-meta-info>
    <li class="govuk-footer__inline-list-item">
      <a routerLink="/privacy-notice"> Privacy Notice </a>
    </li>
    <li class="govuk-footer__inline-list-item">
      <a routerLink="/contact-us"> Contact us </a>
    </li>
    <li class="govuk-footer__inline-list-item">
      <a routerLink="/accessibility"> Accessibility Statement </a>
    </li>
    <li class="govuk-footer__inline-list-item">
      <a routerLink="/legislation"> CCA legislation </a>
    </li>
  </govuk-footer-meta-info>
</govuk-footer>

<cca-timeout-banner />
