<netz-page-heading size="xl">Privacy Notice</netz-page-heading>

<h2 class="govuk-heading-m">Privacy notice: how we use your personal information</h2>

<p>
  The Environment Agency is the controller for the personal data we process for the Climate Change Agreements (CCA)
  Register service under the UK General Data Protection Regulation (UK GDPR) and Data Protection Act 2018 (DPA 2018).
  Our contact details are available on
  <a href="https://www.gov.uk/government/organisations/environment-agency" class="govuk-link" rel="noreferrer noopener">
    GOV.UK
  </a>
</p>

<p>
  Our<a
    href="https://www.gov.uk/government/organisations/environment-agency/about/personal-information-charter"
    class="govuk-link"
    rel="noreferrer noopener"
  >
    personal information charter
  </a>
  explains:
</p>

<ul class="govuk-list govuk-list--bullet govuk-list--spaced">
  <li>how to contact our Data Protection Officer</li>
  <li>what we do with your personal information (personal data) in general</li>
  <li>your rights and how to complain to the Information Commissioner's Office.</li>
</ul>

<h2 class="govuk-heading-m">The personal data we need</h2>
<p>The personal data we collect about you includes:</p>

<ul class="govuk-list govuk-list--bullet govuk-list--spaced">
  <li>full name</li>
  <li>address</li>
  <li>contact details (email, telephone and fax)</li>
  <li>employer name</li>
  <li>job title or position</li>
  <li>
    information on how you use the CCA register, including
    <a href="/cookies" class="govuk-link" rel="noreferrer noopener"> cookies </a>
  </li>
</ul>

<p>We collect these details from:</p>

<ul class="govuk-list govuk-list--bullet govuk-list--spaced">
  <li>the CCA contact person for the sector association</li>
  <li>the Responsible Person and the Administrative Contact for each target unit</li>
  <li>the contact person for each CCA facility</li>
  <li>representatives of an operator who want to access the CCA register</li>
  <li>agents acting on behalf of operators or sector associations who want to access the CCA register</li>
  <li>our staff and our contractor's staff who want to access the CCA register</li>
</ul>

<p>
  We are allowed to process your personal data because we have official authority as the environmental regulator. The
  lawful basis for processing your personal data is to perform a task in the public interest that is set out in law as
  administrator under the Climate Change Agreements (Administration) Regulations 2012 (as amended).
</p>

<p>
  If you don't provide all the information requested then we may not be able to enter into a CCA. If you do not maintain
  the information then your organisation may be liable for a penalty and/or its agreement may be terminated.
</p>

<h2 class="govuk-heading-m">What we do with your personal data</h2>
<p>
  We use your personal data to process your (or your client's) application for a CCA and to maintain your (or your
  client's) CCA.
</p>

<p>We do not use your personal data to make an automated decision or for automated profiling.</p>

<p>We will share your personal data with:</p>
<ul class="govuk-list govuk-list--bullet govuk-list--spaced">
  <li>our contractors who act as our agents for administering the scheme</li>
  <li>credit reference or fraud prevention agencies</li>
  <li>our internet security providers</li>
  <li>the IT suppliers who run the CCA register</li>
</ul>

<h2 class="govuk-heading-m">How long we keep your personal data</h2>
<p>
  We will keep your personal data for the duration of your organisation’s involvement in the CCA scheme plus an
  additional 4 years.
</p>

<h2 class="govuk-heading-m">Where your personal data is processed and stored</h2>
<p>
  We store and process your personal data on our data processors Microsoft and Amazon Web Services, it may be
  transferred and stored securely outside the UK and EEA where it will be subject to equivalent legal protection through
  the use of Model Contract Clauses.
</p>

<cca-back-to-top></cca-back-to-top>
